<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle pr-0">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <img *ngIf="showWhitebgLogo" class="cur-pointer" (click)="goToDashboard()" src="assets/images/easyVTA_logo_for_whiteBG_n.png" alt="" height="1%" width="75%">
     <img *ngIf="!showWhitebgLogo" class="cur-pointer" (click)="goToDashboard()" src="assets/images/easyVTA_logo_for_darkBG.jpg" alt="" height="1%" width="75%">
    
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [nbContextMenuTag]="onMenuItemClick" 
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture"
               color="#da3a00">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
