import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { map, switchMap, catchError, tap } from "rxjs/operators";

import { ApiService } from "../../services/api.service";
import {
  AuthenticationActionTypes,
  Login,
  LoginSuccess,
  LoginFailure,
} from "../actions/authentication.actions";
import { Constant } from "../../constant/constant";
import { NbToastrService } from "@nebular/theme";
import { UserService } from "../../services/user.service";

@Injectable()
export class AuthenticationEffects {
  actions = inject(Actions);

  constructor(
    private apiService: ApiService,
    private router: Router,
    private toastrService: NbToastrService,
    private constant: Constant,
    private userService: UserService
  ) {}

  Login: Observable<any> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActionTypes.LOGIN),
      map((action: Login) => action.payload),
      switchMap((payload) => {
        return this.apiService.login(payload.email, payload.password).pipe(
          map((user) => {
            return new LoginSuccess(user);
          }),
          catchError((error) => {
            let errorMsg = ((error || {}).error || {}).error || "";
            this.showToast(this.constant.TOAST_ACTION.FAILURE, errorMsg);
            return of(new LoginFailure({ error: error }));
          })
        );
      })
    )
  );

  LoginSuccess: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(AuthenticationActionTypes.LOGIN_SUCCESS),
        tap((user) => {
          this.getEmployee();
        })
      ),
    { dispatch: false }
  );

  LoginFailure: Observable<any> = createEffect(
    () => this.actions.pipe(ofType(AuthenticationActionTypes.LOGIN_FAILURE)),
    { dispatch: false }
  );

  public Logout: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(AuthenticationActionTypes.LOGOUT),
        tap((user) => {
          //when the user log out the token and email are removed from localStorage
          localStorage.clear();
          let url = this.constant.REDIRECT_LINKS.LOGIN;
          this.router.navigateByUrl(url);
        })
      ),
    { dispatch: false }
  );

  showToast(status: any, msg: string) {
    this.toastrService.show("", msg, { status });
  }

  getEmployee(): void {
    this.apiService.getEmployee().subscribe(
      (data) => {
        this.userService.setUserDetails(data);
        let url = this.constant.API_URLS.DASHBOARD;
        this.router.navigateByUrl(url);
      },
      (err) => {
        console.log(err);
        let url = this.constant.API_URLS.DASHBOARD;
        this.router.navigateByUrl(url);
      },
      () => {}
    );
  }
}
