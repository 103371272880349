import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Constant } from '../auth/constant/constant';
import { Router } from '@angular/router';
import { Logout } from '../auth/store/actions/authentication.actions';
import { Store } from '@ngrx/store';
import { AuthState } from '../auth/store/app.states';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  showWhitebgLogo: boolean = true;
 
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    }
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  userInfo: any;
  tag: string;

  constructor(private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private menuService: NbMenuService,
    private constant: Constant,
    private router : Router,
    private store: Store<AuthState>) {
    menuService.onItemClick()
      .pipe(filter(({ tag }) => tag === this.tag))
      .subscribe((bag) => {
        let clickEventName = ((bag || {}).item || {}).title || '';
        if (clickEventName == constant.USER_MENU.PROFILE) {
          this.navigateToProfile();
        } else if (clickEventName == constant.USER_MENU.LOG_OUT) {
          this.logOut();
        }
      });
  }

  ngOnInit() {

    this.userInfo = JSON.parse(localStorage.getItem('authentication'));

    let userName = (((((this.userInfo || {}).user || {}).profile || {}).name || {}).first || '') + ' ' + (((((this.userInfo || {}).user || {}).profile || {}).name || {}).last || '');
    this.user = {
      name: userName
    }

    this.currentTheme = this.themeService.currentTheme;

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    if (themeName == 'default') {
      this.showWhitebgLogo = true;
    } else {
      this.showWhitebgLogo = false;
    }
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  navigateToProfile(){
    let url = this.constant.REDIRECT_LINKS.PROFILE;
    this.router.navigateByUrl(url);
  }
  
  logOut(){
    this.store.dispatch(new Logout());
  }

  goToDashboard(){
    this.router.navigateByUrl(this.constant.REDIRECT_LINKS.DASHBOARD);
  }


}
