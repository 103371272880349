//app.states.ts
import { createFeatureSelector } from '@ngrx/store';
import * as authentication from './reducers/authentication.reducer';
import { User } from './../models/vta.user';

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  errorMessage: string | null;
}

export const reducers = {
  authentication: authentication.reducer
};

export const selectAuthenticationState = createFeatureSelector<AuthState>('authentication');