import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NB_AUTH_OPTIONS } from "../../auth.options";
import { getDeepFromObject } from "../../helpers";
import { ApiService } from "../../services/api.service";
import { Constant } from "../../constant/constant";
import { NgForm } from "@angular/forms";
import { NbToastrService } from "@nebular/theme";
import {
  AnimationSettingsModel,
  SpinSettingsModel,
} from "@syncfusion/ej2-splitbuttons";

@Component({
  selector: "verify-user-page",
  styleUrls: ["./vta.verify-user.component.scss"],
  templateUrl: "./vta.verify-user.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyUserComponent implements OnInit {
  resetPasswordActionMessage: string;
  showSuccessMessage: boolean = false;
  showErrorMessage: boolean = false;
  submitted: boolean = false;
  newPassword: string;
  confirmPassword: string;
  verifyToken: string;
  showPasswordFlag: boolean = false;
  showRePasswordFlag: boolean = false;
  slideRight: AnimationSettingsModel = { effect: "SlideRight" };
  spinCenter: SpinSettingsModel = { position: "Center" };
  public isAlreadyVerified: boolean = false;

  constructor(
    private apiService: ApiService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: NbToastrService,
    private constant: Constant
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.verifyToken = ((params || {}).verifyid || "").replace(/\s+/g, "+");
    });
  }

  verifyUser(resetPassForm: NgForm): void {
    this.submitted = true;
    const payload = {
      password: ((resetPassForm || {}).value || {}).password || "",
    };
    this.apiService.verifyUser(payload, this.verifyToken).subscribe(
      (data) => {
        this.showSuccessMessage = true;
        // this.resetPasswordActionMessage = this.constant.RESET_PASSWORD_MSG.SUCCESS;
        this.showToast(
          this.constant.TOAST_ACTION.SUCCESS,
          this.constant.RESET_PASSWORD_MSG.SUCCESS
        );
        resetPassForm.resetForm();
        let url = this.constant.REDIRECT_LINKS.LOGIN;
        this.router.navigateByUrl(url);
      },
      (err) => {
        this.showErrorMessage = true;

        if (err?.error?.error === "ALREADY_VERIFIED") {
          this.isAlreadyVerified = true;
          this.showToast(
            this.constant.TOAST_ACTION.FAILURE,
            this.constant.RESET_PASSWORD_MSG.ALREADY_VERIFIED
          );
          resetPassForm.resetForm();
        } else {
          this.showToast(
            this.constant.TOAST_ACTION.FAILURE,
            this.constant.RESET_PASSWORD_MSG.FAILURE
          );
          resetPassForm.resetForm();
        }
      },
      () => {}
    );
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  showToast(status: any, msg: string) {
    this.toastrService.show("", msg, { status });
  }

  togglePassword(id) {
    if (id == "input-password") {
      this.showPasswordFlag = !this.showPasswordFlag;
    } else if (id == "input-re-password") {
      this.showRePasswordFlag = !this.showRePasswordFlag;
    }
    let passEleType = (<HTMLInputElement>document.getElementById(id)).type;
    if (passEleType == "password") {
      (<HTMLInputElement>document.getElementById(id)).type = "text";
    } else if (passEleType == "text") {
      (<HTMLInputElement>document.getElementById(id)).type = "password";
    }
  }
}
