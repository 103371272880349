import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import { Constant } from "../constant/constant";
import { environment } from "../../../../../environments/environment";
import { ApiService } from "./api.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class StartupService {
  baseUrl: string;
  navigationData: any;

  constructor(
    private http: HttpClient,
    private constant: Constant,
    private apiService: ApiService,
    private userService: UserService
  ) {
    if (location.origin == constant.DOMAIN_URLS.DEV) {
      this.baseUrl = environment.dev_url;
    } else if (location.origin == constant.DOMAIN_URLS.STAGING) {
      this.baseUrl = environment.staging_url;
    } else if (location.origin == constant.DOMAIN_URLS.PRODUCTION) {
      this.baseUrl = environment.production_url;
    } else if (location.origin == constant.DOMAIN_URLS.LOCAL) {
      this.baseUrl = environment.dev_url;
    }
  }

  getToken(): string {
    let userInfo = JSON.parse(localStorage.getItem("authentication"));
    return ((userInfo || {}).user || {}).token || "";
  }

  getHttpOptions() {
    let token = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    return httpOptions;
  }

  load(): Promise<any> {
    if (
      location.pathname == "/verify" ||
      location.pathname == "/resetpassword"
    ) {
    } else {
      let userInfo = JSON.parse(localStorage.getItem("authentication"));
      let httpOptions = this.getHttpOptions();
      let url = this.baseUrl + this.constant.API_URLS.GET_EMPLOYEE;
      if (!!userInfo && (userInfo || {}).isAuthenticated) {
        return this.http
          .get(url, httpOptions)
          .map((res: Response) => res)
          .toPromise()
          .then((data: any) => {
            this.userService.setUserDetails(data);
            this.navigationData = {
              loggedInStatus: true,
              isTokenValid: true,
            };
          })
          .catch((err) => {
            if (
              err instanceof HttpErrorResponse &&
              (err.status == this.constant.HTTP_CODES.UNAUTHORIZED ||
                err.status == this.constant.HTTP_CODES.FORBIDDEN)
            ) {
              // Handle 401 error
              this.navigationData = {
                loggedInStatus: true,
                isTokenValid: false,
              };
            }
          });
      } else {
        this.navigationData = {
          loggedInStatus: false,
          isTokenValid: false,
        };
      }
    }
  }

  get getNavigationData() {
    return this.navigationData;
  }
}
