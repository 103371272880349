import { Pipe, PipeTransform } from '@angular/core';



@Pipe({
    name: 'formateString'
})
export class FormateString implements PipeTransform {

    transform(value: string, formate: string, u: boolean = false): string {
        if (!value) {
            return "";
        }
        let newVal = value.replace(/[^0-9a-z]/gi, '')
        let valArr = value.split('');
        let fm = formate.split('');
        for (var i = 0; i < fm.length; i++) {
            if (fm[i] == "#" && valArr.length > 0) {
                fm[i] = valArr.splice(0, 1)[0];
            }
            else if (valArr.length <= 0) {
                fm[i] = '';
            }
        }
        newVal = fm.join('');
        return u ? newVal.toUpperCase() : newVal;
    }
}

