<nb-layout windowMode [withScroll]="false">
  <nb-layout-header>
    <div class="header-container">
      <div class="logo-container">
        <img
          (click)="goToDashboard()"
          class="cur-pointer"
          src="assets/images/easyVTA_logo_for_whiteBG_n.png"
          alt=""
          height="1%"
          width="75%"
        />
      </div>
    </div>
  </nb-layout-header>

  <nb-layout-column>
    <main class="privacy-policy">
      <header class="privacy-policy__header">
        <h6 class="header d-flex align-items-center justify-content-center">
          <a href="http://www.easyvta.com/" class="header__site"
            >www.easyvta.com</a
          >
          Privacy Policy
        </h6>

        <p class="privacy-policy__subtitle">
          Effective date: 1st day of April, 2023
        </p>
      </header>

      <p>
        <a href="http://www.easyvta.com/">www.easyvta.com</a> (the “Site”) is
        owned and operated by A2J Software Ltd. A2J Software Ltd. Can be
        contacted at
        <a href="mailto:contact@easyvta.com">contact&#64;easyvta.com</a>
      </p>

      <br />

      <section class="purpose section">
        <h6 class="section__title">Purpose</h6>
        <p>
          The purpose of this privacy policy (this “Privacy Policy”) is to
          inform users of our Site of the following:
        </p>

        <ol>
          <li>The personal data we will collect;</li>
          <li>Use of collected data;</li>
          <li>Who has access to the data collected;</li>
          <li>The rights of Site users; and</li>
          <li>The Site' cookie policy</li>
        </ol>

        <p>
          This Privacy Policy applies in addition to the terms of conditions of
          our Site.
        </p>
      </section>

      <section class="purpose section">
        <h6 class="section__title">Consent</h6>
        <p>By using our Site users agree that they consent to:</p>
        <ol>
          <li>The conditions set out in this Privacy Policy; and</li>
          <li>
            The collection, use, and retention of the data listed in this
            Privacy Policy.
          </li>
        </ol>
      </section>

      <section class="purpose section">
        <h6 class="section__title">How We Use Personal Data With</h6>

        <div class="section__subtitle">Employees</div>

        <p class="mb-4">
          We may disclose user data to any member of our organization who
          reasonably needs access to user data to achieve the purposes set out
          in the Privacy Policy.
        </p>

        <div class="section__subtitle">Other Disclosures</div>

        <p>
          We will no sell or share your data with other third parties, except in
          the following cases:
        </p>

        <ol>
          <li>If the law requires it;</li>
          <li>If it is required for any legal processing;</li>
          <li>To prove or protect our legal rights; and</li>
          <li>
            To buyers or potential buyers of this company in the event that we
            seek to sell the company
          </li>
        </ol>

        <p>
          If you follow hyperlinks from our Site to another Site, please note
          that we are not responsible for and have no control over their privacy
          policies and practices.
        </p>
      </section>

      <section class="purpose section">
        <h6 class="section__title">How Long We Store Personal Data</h6>
        <p>
          User data will be stored until the purpose the data was collected for
          has been achieved. You will be notified if your data is kept for
          longer than this period.
        </p>
      </section>

      <section class="purpose section">
        <h6 class="section__title">How We Protect Your Personal Data</h6>
        <p>
          In order to protect your security, we use the strongest available
          browser encryption and store all of our data on serves in secure
          facilities. All data is only accessible to our employees. Our
          employees are bound by strict confidentiality agreements and a breach
          of this agreement would result in the employee’s termination.
        </p>

        <p>
          While we take all reasonable precautions to ensure that user data is
          secure and that users are protected, there always remains the risk of
          harm. The Internet as a whole can be insecure at times and therefore
          we are unable to guarantee the security of user data beyond what is
          reasonably practical.
        </p>
      </section>

      <section class="purpose section">
        <h6 class="section__title">Children</h6>

        <p>
          We do not knowingly collect or use personal data from children under
          13 years of age. If we learn that we have collected personal data from
          a child under 13 years of age, the personal data will be deleted as
          soon as possible. If a child under 13 years of age has provided us
          with personal data their parent or guardian may contact our privacy
          officer at
          <a href="mailto:contact@easyvta.com">contact&#64;easyvta.com</a>
        </p>
      </section>

      <section class="purpose section">
        <h6 class="section__title">
          How to Access, Modify, Delete, or Challenge the Data Collected
        </h6>

        <p>
          If you would like to know if we have collected your personal data, how
          we have used your personal data, if we have disclosed your personal
          data and to who we disclosed your personal data, or if you would like
          your data to be deleted or modified in any way, please contact our
          privacy officer at
          <a href="mailto:contact@easyvta.com">contact&#64;easyvta.com</a>
        </p>
      </section>

      <section class="purpose section">
        <h6 class="section__title">Cookie Policy</h6>

        <p>
          A cookie is a small file, stored on a user’s hard drive by a website.
          Its purpose is to collect data relating to the user’s browsing habits.
          You can choose to be notified each time a cookie is transmitted. You
          can also choose to disable cookies entirely in your internet browser,
          but this may decrease the quality of your user experience.
        </p>

        <p class="mb-4">We use the following types of cookies on our Site:</p>

        <ol>
          <li>
            <div class="section__subtitle">Functional Cookies</div>
            <p>
              Functional cookies are used to remember the selections you make on
              our Site so that your selections are saved for your next visits;
              and
            </p>
          </li>
          <li>
            <div class="section__subtitle">Third-Party Cookies</div>
            <p>
              Third-party cookies are created by a website other than ours. We
              may use third-party cookies to achieve the functionality and
              performance of our app. Third-party cookies may use to monitor
              user preferences to tailor advertisements around their interest.
            </p>
          </li>
        </ol>
      </section>

      <section class="purpose section">
        <h6 class="section__title">Modifications</h6>

        <p>
          The Privacy Policy may be amended from time to time in order to
          maintain compliance with the law and to reflect any changes to our
          data collection process. When we amend this Privacy Policy we will
          update the “Effective Data” at the tip of this Privacy Policy. We
          recommend that our users periodically review our Privacy Policy to
          ensure that they are notified of any updates. If necessary, we may
          notify users by email of changes to the Privacy Policy.
        </p>
      </section>

      <section class="purpose section">
        <h6 class="section__title">Contact Information</h6>

        <p>
          If you have any questions, concerns or complaints, you can contact our
          privacy officer at
          <a href="mailto:contact@easyvta.com">contact&#64;easyvta.com</a>
        </p>
      </section>
    </main>
  </nb-layout-column>
</nb-layout>
