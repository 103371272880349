
export class Register {
    name: string;
    email: string;
    reEmail : string;
    firstName: string;
    middleName: string;
    lastName: string;
    phone?:string;
    phoneNumberExtension : string;
    address? : string;
    city?: string;
    state?: string;
    country?: string;
    zip?: string;
    fax? : number;
    workhr? : number;
    vpolicy? : string;
    website? : string;


  }

  
