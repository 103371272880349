import { Component, OnInit } from "@angular/core";
import { Constant } from "../@theme/components/auth/constant/constant";
import { Router } from "@angular/router";

@Component({
  selector: "ngx-privacy-policy",
  templateUrl: "./vta.privacy-policy.component.html",
  styleUrls: ["./vta.privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private constant: Constant, private router: Router) {}

  ngOnInit(): void {}

  goToDashboard() {
    this.router.navigateByUrl(this.constant.REDIRECT_LINKS.DASHBOARD);
  }
}
