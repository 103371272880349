import { Observable } from "rxjs";

export interface User {
  name: string;
  picture: string;
}

export interface Contacts {
  user: User;
  type: string;
}

export interface RecentUsers extends Contacts {
  time: number;
}

export abstract class UserData {
  abstract getUsers(): Observable<User[]>;
  abstract getContacts(): Observable<Contacts[]>;
  abstract getRecentUsers(): Observable<RecentUsers[]>;
}

export type TEmployee = {
  id: string;
  reportingTo: string;
  user: string;
  status: number;
  email: string;
  name: {
    first: string;
    middle: string;
    last: string;
  };
  setting: {
    allowedVacations: {};
  };
  date: {
    created: string;
    modified: string;
    onboarded: string;
  };
  active: boolean;
  organization: string;
  role: string;
  phones: {
    number: string;
    extension: string;
  };
  details: {
    birthday: string;
    hireDate: string;
    salary: number;
    title: string;
    gender: string;
    notes: string;
    terminationDate: string;
  };
  department?: string;
  departmentName?: string;
};

export type TTeamEmployee = {
  id: string;
  role: string;
  email: string;
  status: number;
  employeeName: string;
  reportingManager: string;
  name: {
    first: string;
    middle: string;
    last: string;
  };
};

export type TOrgChartEmployee = {
  id: string;
  name: string;
  role: string;
  color?: string;
  reportingTo?: string;
  title?: string;
  terminationDate?: string;
  pendingRequests?: boolean;
  cancelRequests?: boolean;
  transferRequestsTo?: string;
};

export type TEmployeeHistory = {
  id?: string;
  organization?: string;
  employee: string;
  requestTo?: string;
  requestDateFrom: string;
  requestDateTo: string;
  requestDays: number | string;
  requestHours?: number;
  requestType: string;
  status: number;
  requestSubmittedOn?: string;
  requestComments?: string;
  requestRequestedBy?: string;
  requestRequestedByName?: {
    first: string;
    middle: string;
    last: string;
  };
  sortDate: string;
  details: {
    days: number;
    hours: number;
    totalHours: string;
    calcHours: string;
    runningTotal: {
      totalHours: number;
      days: number;
      hours: number;
    };
  };
  vacationPolicyUsed?: string;
};

export interface EmployeeStatusDetails {
  isTerminatedOrInactive: boolean;
  tooltipContent: string;
}
