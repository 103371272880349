<header class="header-area">
  <div class="navbar-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="/">
              <img
                src="assets/images/easyVTA_logo_for_whiteBG.png"
                alt=""
                height="1%"
                width="25%"
              />
            </a>

            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
            </button>

            <div
              class="collapse navbar-collapse sub-menu-bar"
              id="navbarSupportedContent"
            >
              <ul id="nav" class="navbar-nav ml-auto">
                <li class="nav-item active">
                  <a class="nav-link page-scroll" href="/register">Register</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link page-scroll" href="/login">Login</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div
    id="home"
    class="header-hero bg_cover"
    style="background-image: url(assets/images/banner-bg.svg)"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="header-hero-content text-center">
            <h2
              class="header-title wow fadeInUp"
              data-wow-duration="1.3s"
              data-wow-delay="0.5s"
            >
              Tracking time off has never been this easy
            </h2>
            <p
              class="text wow fadeInUp"
              data-wow-duration="1.3s"
              data-wow-delay="0.8s"
            >
              EasyVTA is innovative online time off tracking software that helps
              your employees track their time off in an effortless manner.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
            class="header-hero-image text-center wow fadeIn"
            data-wow-duration="1.3s"
            data-wow-delay="1.4s"
          >
            <img src="assets/images/header-hero.png" alt="hero" />
          </div>
        </div>
      </div>
    </div>
    <div id="particles-1" class="particles"></div>
  </div>
</header>

<footer id="footer" class="footer-area pt-200" style="margin-top: 200px">
  <div class="container" style="padding-top: -100px">
    <div
      class="subscribe-area wow fadeIn"
      data-wow-duration="1s"
      data-wow-delay="0.5s"
    >
      <div class="row">
        <div class="col-lg-6">
          <div class="subscribe-content mt-45">
            <h2 class="subscribe-title">
              Subscribe Our Newsletter <span>get reguler updates</span>
            </h2>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="subscribe-form mt-50">
            <form action="#">
              <input type="text" placeholder="Enter eamil" />
              <button class="main-btn">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-widget pb-100">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-8">
          <div
            class="footer-about mt-50 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <a class="logo" href="#">
              <!-- <img src="assets/images/logo.svg" alt="logo"> -->
            </a>
            <p class="text"></p>
            <ul class="social">
              <li>
                <a href="#"><i class="lni lni-facebook-filled"></i></a>
              </li>
              <li>
                <a href="#"><i class="lni lni-twitter-filled"></i></a>
              </li>
              <li>
                <a href="#"><i class="lni lni-instagram-filled"></i></a>
              </li>
              <li>
                <a href="#"><i class="lni lni-linkedin-original"></i></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-7 d-flex align-items-end">
          <div class="footer-link d-flex mt-50 justify-content-md-between">
            <div
              class="link-wrapper wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <div class="footer-title">
                <!-- <h4 class="title">Quick Link</h4> -->
              </div>
              <ul class="link d-flex align-items-end" style="gap: 16px">
                <!-- <li><a href="#">Road Map</a></li> -->
                <li>
                  <a
                    href="javascript:void(0)"
                    (click)="handleNavigation('/privacy-policy')"
                  >
                    Privacy Policy
                  </a>
                </li>
                <!-- <li><a href="#">Refund Policy</a></li> -->
                <li>
                  <a
                    href="javascript:void(0)"
                    (click)="handleNavigation('/terms-and-conditions')"
                  >
                    Terms of Service
                  </a>
                </li>
                <!-- <li><a href="#">Pricing</a></li> -->
              </ul>
            </div>
            <!-- <div
              class="link-wrapper wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.6s"
            >
              <div class="footer-title">
                <h4 class="title">Resources</h4>
              </div>
              <ul class="link">
                <li><a href="#">Home</a></li>
                <li><a href="#">Page</a></li>
                <li><a href="#">Portfolio</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Contact</a></li>
              </ul>
            </div> -->
          </div>
        </div>
        <div class="col-lg-3 col-md-5 col-sm-5">
          <!-- <div class="footer-contact mt-50 wow fadeIn" data-wow-duration="1s"
							data-wow-delay="0.8s">
							<div class="footer-title">
								<h4 class="title">Contact Us</h4>
							</div>
							<ul class="contact">
								<li>+809272561823</li>
								<li><a href="/cdn-cgi/l/email-protection" class="__cf_email__"
										data-cfemail="8fe6e1e9e0cfe8e2eee6e3a1ece0e2">[email&#160;protected]</a>
								</li>
								<li>www.yourweb.com</li>
								<li>123 Stree New York City , United <br> States Of America 750.
								</li>
							</ul>
						</div> -->
        </div>
      </div>
    </div>
  </div>
  <div id="particles-2"></div>
</footer>

<a href="#" class="back-to-top"><i class="lni lni-chevron-up"></i></a>
