import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  accessToken: string;
  isActive: boolean;
  organizationId: string;
  organizationName: string;
  userRole: string;
  reportingTo: string;

  constructor() {}

  setUserDetails(data: any): void {
    this.userId = (data || {}).id || "";
    this.email = (data || {}).emial || "";
    this.firstName = ((data || {}).name || {}).first || "";
    this.lastName = ((data || {}).name || {}).last || "";
    this.middleName = ((data || {}).name || {}).middle || "";
    this.organizationId = (data || {}).organization || "";
    this.userRole = (data || {}).role || "";
    this.reportingTo = (data || {}).reportingTo || "";
  }
}
