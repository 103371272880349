export class User {
    email: string;
    password?: string;
    token?: string;
    user?: string;
    profile?: Profile
  }

  class Profile {
    email: string;
    id: string;
    name: {first:string, last:string}
  }
  