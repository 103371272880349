//authentication.reducer.ts
import { User } from '../../models/vta.user';
import { AuthenticationActionTypes, AuthenticationActions } from '../actions/authentication.actions';
import {AuthState} from './../app.states';


//set the initial state with localStorage
export const initialState: AuthState = {
  isAuthenticated: false,
  user: new User(),
  errorMessage: null
};

export function reducer(state = initialState, action: AuthenticationActions): AuthState {
  switch (action.type) {
    case AuthenticationActionTypes.LOGIN_SUCCESS: {
      
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        errorMessage: null
      };
    }
    case AuthenticationActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        errorMessage: 'Wrong credentials.'
      };
    }
    case AuthenticationActionTypes.LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}