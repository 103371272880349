<div class="request-pass">
  <div class="request-pass__wrapper">
    <div class="request-pass__header navbar-area">
      <nav class="navbar navbar-expand-lg">
        <a class="navbar-brand" href="/">
          <img
            src="assets/images/easyVTA_logo_for_whiteBG.png"
            alt=""
            height="1%"
            width="25%"
          />
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          [ngClass]="{ active: isTogglerActive }"
          (click)="toggleNavbar()"
        >
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse sub-menu-bar"
          id="navbarSupportedContent"
        >
          <ul id="nav" class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link page-scroll" href="/register">Register</a>
            </li>
            <li class="nav-item">
              <a class="nav-link page-scroll" href="/login">Login</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <div class="request-pass__container">
      <div class="request-pass__left" [ngClass]="{ expanded: isTogglerActive }">
        <p *ngIf="!showSuccessMessage && !showErrorMessage">
          Enter your email address and we’ll send a link to reset your password
        </p>

        <nb-alert *ngIf="showErrorMessage" outline="danger" role="alert">
          <p class="alert-title">
            <b>{{ requestPasswordActionMessage }}</b>
          </p>
        </nb-alert>

        <nb-alert *ngIf="showSuccessMessage" outline="success" role="alert">
          <p class="alert-title">
            <b>{{ requestPasswordActionMessage }}</b>
          </p>
        </nb-alert>

        <form
          (ngSubmit)="requestPass(form)"
          #form="ngForm"
          aria-labelledby="title"
          class="form"
          *ngIf="!showSuccessMessage && !showErrorMessage"
        >
          <div class="form-group template full-width">
            <div
              id="mobile-no"
              class="e-float-input"
              [ngClass]="{ 'e-success': email.valid }"
            >
              <input
                type="text"
                id="email"
                name="email"
                [(ngModel)]="emailAddress"
                #email="ngModel"
                required
              />

              <span class="e-float-line"></span>
              <label
                class="e-float-text"
                [ngClass]="{
                  'e-label-top': email !== '',
                  'e-label-bottom': email == ''
                }"
                for="name"
                >Email</label
              >
            </div>
            <span
              class="e-error"
              *ngIf="
                email.errors &&
                email.errors.required &&
                (email.dirty || email.touched)
              "
            >
              Enter your email address
            </span>

            <span
              class="e-error"
              *ngIf="
                !validateEmail(emailAddress) && (email.dirty || email.touched)
              "
            >
              Enter a valid email address
            </span>
          </div>

          <div class="form-group template center">
            <div class="submitBtn">
              <button
                style="width: 12.5rem"
                class="submit-btn e-btn"
                id="submit-btn"
                ejs-progressbutton
                content="Request Password"
                [enableProgress]="true"
                [spinSettings]="spinCenter"
                [animationSettings]="slideRight"
                cssClass="e-outline e-success"
                type="submit"
                [disabled]="
                  submitted || !form.valid || !validateEmail(emailAddress)
                "
              >
                Request Password
              </button>
            </div>
          </div>
        </form>
      </div>

      <div class="request-pass__right">
        <section class="info-msg" aria-label="Register">
          <div class="info-msg__step">
            To register your organization to use easyVTA please
            <a class="login-link" routerLink="../register">click here.</a>
          </div>

          <div class="info-msg__step">
            If you have been invited to join easyVTA by your organization,
            please refer to your invitation email.
          </div>

          <div class="info-msg__step">
            If you have already accepted an invitation from your organization
            and have set up your account, please log in
            <a class="login-link" routerLink="../login">here.</a>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
